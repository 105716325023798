
import { Component, Vue } from 'vue-property-decorator'
import FSelect from '@/components/Form/FSelect.vue'
import { TerritoryService } from '@/services/TerritoryService';
import { bus } from '@/main';

@Component({ 
  components:{ 
    FSelect
  }
})
export default class TerritorySelector extends Vue {
  store = TerritoryService;
  
  get territories(){ 
    return this.store.availableTerritories;
  }

  mounted(){ 
    TerritoryService.getAvailableTerritories();
  }

  onInput(){ 
    this.$nextTick();
    bus.$emit('territoryUpdated', this.store.territoryId);
  }
}
