
import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import NavTabs from '@/components/Tabs/NavTabs.vue'
import TerritorySelector from './TerritorySelector.vue'
import { RegisterSongService } from '@/services/RegisterSongService'
import { i18n } from '@/main'

@Component({
  components: {
    CardWithTitle,
    NavTabs, 
    TerritorySelector
  }
})
export default class IntellectualPropertyTemplate extends Vue {

  get tabs() { 
    return [
      {
        shortenedLabel: i18n.t('G.LIT_Search').toString(), 
        label: i18n.t('R.LIT_SearchAllSongs').toString(), 
        to:"/intellectualproperty/songs"
      },
      {
        shortenedLabel: i18n.t('R.LIT_Catalogs').toString(), 
        label: i18n.t('R.LIT_ViewCatalogs').toString(),
        to:"/intellectualproperty/catalogs"
      },
      {
        shortenedLabel: i18n.t('R.LIT_Register').toString(), 
        label: i18n.t('R.LIT_RegisterSongs').toString(), 
        to:"/intellectualproperty/registersongs"
      },
    ];
  }

  get showTerritorySelector(){ 
    return this.$route.name !== 'Register Songs' && this.$route.name !== 'Register Song Details';
  }

  mounted() { 
    RegisterSongService.getCultures();
  }
}
